var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.user.role === 'manager')?_c('div',{staticClass:"d-flex align-items-center mt-3"},[_c('label',{staticClass:"mr-1 h3"},[_vm._v("Bon d'échanges")])]):_vm._e(),_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Recherche")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Recherche","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)]),_c('b-form-group',[_c('b-button',{staticClass:"ml-1 d-inline-block",attrs:{"to":{ name: 'bon-echange-add' },"variant":"gradient-primary"}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"ml-1"},[_vm._v("Ajouter bon échange")])],1)],1)],1),(_vm.isLoading === true)?_c('div',{staticClass:"text-center mb-2"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1):_vm._e(),_c('vue-good-table',{attrs:{"current-page":_vm.currentPage,"columns":_vm.columns,"rows":_vm.exchangeVouchers,"rtl":_vm.direction,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm },"pagination-options":{
      enabled: true,
      perPage:_vm.pageLength
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'id')?_c('div',{staticClass:"text-nowrap"},[_c('qrcode-vue',{staticStyle:{"display":"inline"},attrs:{"value":props.row.id.toString(),"size":_vm.size,"level":"H"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(props.row.id))])],1):(props.column.field === 'fullName')?_c('div',{staticClass:"text-nowrap"},[_c('b-avatar',{staticClass:"mx-1",attrs:{"src":props.row.avatar}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.fullName))])],1):(props.column.field === 'status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status)}},[_vm._v(" "+_vm._s(props.row.status)+" ")])],1):(props.column.field === 'action')?_c('span',[_c('span',[_c('feather-icon',{staticClass:"cursor-pointer mr-1",attrs:{"id":("invoice-row-" + (props.row.id) + "-show-icon"),"icon":"EyeIcon","size":"16"},on:{"click":function($event){return _vm.$router.push({
                name: 'bon-echange-show',
                params: { id: props.row.id} ,
              })}}}),_c('b-tooltip',{staticClass:"cursor-pointer",attrs:{"title":"Détail","target":("invoice-row-" + (props.row.id) + "-show-icon")}})],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Affichage 1 à ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3','5','10']},on:{"input":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap "},[_vm._v(" de "+_vm._s(_vm.count)+" entrées ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"total-rows":_vm.count,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function($event){return _vm.pageNext(_vm.currentPage)}},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }